import React from "react";
import "../../Assets/Styles/Header/Header.styles.scss";
import { Nav } from "../Nav/Nav";

export const Header: React.FC = () => {
  return (
    <>
      <div className="header">
        <h1 className="header-title">Luma Action</h1>
        <hr className="upper-hr" />
        <Nav />
        <hr className="lower-hr" />
      </div>
    </>
  );
};
