import React from "react";

export const Services: React.FC = () => {
  return (
    <div className="services-main">
      <h2>Services</h2>
      <h3>Policy</h3>
      <p>
        As registered lobbyists, Luma Action can assist with legislative,
        budget, and agency level advocacy at the state.
      </p>
      <h3>Advocacy</h3>
      <p>
        Luma Action can partner with your organization to develop messaging,
        digital content and press engagement for the issues that matter most to
        you.
      </p>
      <h3>Strategic Development</h3>
      <p>
        Luma Action can provide guidance to your organization, depending on the
        needs and goals you identify. This includes everything from development
        and strategic planning, to devising legislative priorities and advocacy
        campaigns.{" "}
      </p>
    </div>
  );
};
