import React from "react";
import { Link } from "react-router-dom";

export const Nav: React.FC = () => {
  return (
    <>
      <nav className="nav-header">
        <span>
          <Link to="/">About Us</Link>
        </span>
        <span>
          <Link to="/services">Services</Link>
        </span>
        <span>
          <Link to="/contact-us">Contact Us</Link>
        </span>
      </nav>
    </>
  );
};
