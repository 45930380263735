import React from "react";
import "../../Assets/Styles/Main/Main.styles.scss";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AboutUs, Services, Contact } from "../../Components/index";

export const Main: React.FC = () => {
  return (
    <>
      <Router>
        <div className="main">
          <div className="main-child-div">
            <Header />
            <Routes>
              <Route path="/" Component={AboutUs} />
              <Route path="/services" Component={Services} />
              <Route path="/contact-us" Component={Contact} />
            </Routes>
            <Footer />
          </div>
        </div>
      </Router>
    </>
  );
};
