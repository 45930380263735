import React from "react";
import { AiOutlineTrademarkCircle } from "react-icons/ai";
import "../../Assets/Styles/Footer/Footer.styles.scss";

export const Footer: React.FC = () => {
  return (
    <div className="footer">
      <hr />
      <h5>
        Luma Action <AiOutlineTrademarkCircle />
      </h5>
    </div>
  );
};
