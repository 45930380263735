import React from "react";

import header2 from "../../Assets/Images/purple_hiRes_header.jpeg";
import "../../Assets/Styles/Landing/Landing.styles.scss";
import { Main } from "../../Layouts/Main/Main";

export const Landing: React.FC = () => {
  return (
    <div className="landing-flex-box">
      <Main />
      <div className="img-container">
        <img className="headerIMG" src={header2} alt="..." />
      </div>
    </div>
  );
};
