import React from "react";
import { Landing } from "./Pages/Landing/Landing";

export const App: React.FC = () => {
  return (
    <>
      <Landing />
    </>
  );
};
