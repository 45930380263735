import React from "react";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface FormData {
  from_name: string;
  from_email: string;
  message: string;
}

function isValidEmail(email: string): boolean {
  // Regular expression for a basic email format
  const emailPattern: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  return emailPattern.test(email);
}

export const Contact: React.FC = () => {
  const [formData, setFormData] = React.useState<FormData>({
    from_name: "",
    from_email: "",
    message: "",
  });

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const sendEmail = async (e: any) => {
    e.preventDefault();
    if (!formData.from_name || !formData.from_email || !formData.message) {
      toast.error("Fill out all inputs!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return 0;
    }
    if (isValidEmail(formData.from_email) === false) {
      toast.error("Invalid email address!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return 0;
    }

    const serviceID = process.env.REACT_APP_SERVICE_ID;
    const templateID = process.env.REACT_APP_TEMPLATE_ID;
    const userID = process.env.REACT_APP_USER_PUBLIC_ID;

    if (!serviceID) {
      throw new Error(
        "REACT_APP_SERVICE_ID is not defined in the environment variables."
      );
    }
    if (!templateID) {
      throw new Error(
        "REACT_APP_TEMPLATE_ID is not defined in the environment variables."
      );
    }

    const templateParams = {
      from_name: formData.from_name,
      from_email: formData.from_email,
      message: formData.message,
    };

    try {
      const response = await emailjs.send(
        serviceID,
        templateID,
        templateParams,
        userID
      );
      setFormData({
        from_name: "",
        from_email: "",
        message: "",
      });
      console.log("Email sent:", response);
      toast.success("Success, email has been sent!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Error sending email! Try again later!", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <div className="form-main">
      <h2>Contact Us Below</h2>
      <p>
        We’d love to hear from you. Use the contact form below or send an email
        to contact at lumaaction.org.{" "}
      </p>
      <form className="form-form-main">
        <input
          className="form-input"
          type="text"
          name="from_name"
          placeholder="Name:"
          value={formData.from_name}
          onChange={handleInputChange}
        />
        <br />
        <input
          className="form-input"
          type="email"
          name="from_email"
          placeholder="Email:"
          value={formData.from_email}
          onChange={handleInputChange}
        />
        <br />
        <textarea
          className="form-text-field"
          name="message"
          placeholder="How can we help?"
          value={formData.message}
          onChange={handleInputChange}
        />
        <br />
        <div className="button-parent-div">
          <button onClick={sendEmail} className="button-component">
            Send
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};
