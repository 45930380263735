import React from "react";

export const AboutUs: React.FC = () => {
  return (
    <div className="about-us-main">
      <h2>About Us</h2>
      <p>
        Luma Action offers dynamic policy planning, incisive advocacy and
        strategic organizational development for partners and clients. Luma
        Action provides extensive experience working on complex issues,
        empowering people and uplifting communities. Let Luma Action illuminate
        your path towards positive change.
      </p>
      <h2>Co-Founders</h2>
      <h3>Jackie Gonzalez</h3>
      <p>
        Jackie Gonzalez is an attorney with more than a decade of organizing,
        advocacy and policy experience. She began her career as a direct legal
        services attorney and went on to hold leadership positions at various
        immigrants rights’ organizations where she played an instrumental role
        in building and expanding deportation defense services for communities
        across the state of California, including Immigrant Defense Advocates,
        Legal Services for Children, the California Collaborative for Immigrant
        Justice, Centro Legal de la Raza, and Dolores Street Community Services.
        In 2019 during the wake of the Trump presidency, Jackie brought her
        experience to Sacramento, where she successfully advocated and secured
        $4.7 million in the state budget for the establishment of the California
        Immigrant Justice Fellowship. The Fellowship, which Jackie developed in
        partnership with local and regional stakeholders, is the first state
        sponsored program to increase access to counsel in the most underserved
        regions of the state and defend California’s residents against family
        separation. Thanks to the model’s success the program was expanded in
        2022 to new fellows and regions.
        <p />
        Since then Jackie has developed an extensive policy background. She is
        sought out for her experience on issues related to immigration where she
        has been at the forefront of some of the state’s most innovative
        legislation ({""}
        <a href="https://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201920200AB32">
          AB-32,
        </a>{" "}
        <a href="https://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201920200AB32">
          AB 3228,
        </a>{" "}
        <a href="https://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201920200AB32">
          AB 1140,
        </a>{" "}
        <a href="https://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201920200AB32">
          AB 263,
        </a>{" "}
        <a href="https://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201920200AB32">
          AB 2632,
        </a>{" "}
        and{" "}
        <a href="https://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201920200AB32">
          AB 280,
        </a>{" "}
        ). Jackie received her undergraduate degree from Harvard University and
        her law degree from the University of California, Berkeley School of
        Law.
      </p>
      <h4>Email: jackie@lumaaction.org</h4>
      <h3>Hamid Yazdan Panah, Advocacy Director</h3>
      <p>
        Hamid Yazdan Panah is an attorney and co-founder of Luma Action. Hamid
        has experience as an immigration attorney, community organizer, and
        advocate. This includes assisting in the development of regional rapid
        response networks to respond to ICE raids during the Trump
        administration, spearheading advocacy campaigns to challenge and close
        immigration detention facilities, and securing lifesaving vaccine access
        for immigrants who were detained in California at the height of the
        pandemic. Hamid has significant expertise in drafting complex
        immigration legislation at the state level, fostering close
        communications and press relations, as well as campaign development and
        implementation. In addition, Hamid has advocated and organized in the
        Iranian diaspora, and has written extensively on the issue of human
        rights abuses in Iran. His perspective on legal issues and human rights
        has appeared in{" "}
        <a href="http://blogs.reuters.com/great-debate/2015/08/17/u-s-just-made-a-deal-with-the-country-that-leads-world-in-per-capita-execution-rate/">
          Reuters,{" "}
        </a>
        <a href="https://www.cnn.com/2018/01/03/opinions/iran-protest-motivation-opinion-panah/index.html">
          CNN,{" "}
        </a>
        <a href="https://www.sfchronicle.com/opinion/openforum/article/Open-Forum-Stopping-ICE-raids-begins-with-ending-14097799.php">
          SF Chronicle,{" "}
        </a>
        <a href="https://www.google.com/url?q=https://www.latimes.com/opinion/story/2021-02-15/coronavirus-vaccination-immigration-ice-detention-center-otay-mesa&sa=D&source=docs&ust=1691738691737729&usg=AOvVaw17n13fkmhq9Js1fkUVSwuO">
          LA Times,{" "}
        </a>
        <a href="https://www.newsweek.com/if-iranians-are-nostalgic-it-isnt-shahs-brutality-opinion-1816492">
          Newsweek{" "}
        </a>
        and more.
      </p>
      <h4>Email: hamid@lumaaction.org</h4>
    </div>
  );
};
